import React from "react";
import { Link } from 'react-router-dom';
import Ecomfooter from '../footer/footershop';
import Ecomheader from '../header/headershop';
import pagenotfound from '../../src/images/lightenning.png';
const PageNotFound = () => {
    return(
        <div>
            <Ecomheader />
            <div className="ec-content-wrapper">
				<div className="content">
					<div className="error-wrapper border bg-white px-5">
						<div className="row justify-content-center align-items-center text-center">
							<div className="col-xl-4">
								<h1 className="bold error-title">404</h1>
								<p className="pt-4 pb-5 error-subtitle">Looks like something went wrong.</p>
								<Link to="/HomePage" className="btn btn-dark btn-md btn-rounded">Back to Home</Link>
							</div>

							<div className="col-xl-6 pt-5 pt-xl-0 text-center">
								<img src={pagenotfound} className="img-fluid" alt="Error Page Image"/>
							</div>
						</div>
					</div>
				</div>
                </div>
            <Ecomfooter />
        </div>
    );
}
export default PageNotFound;
import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, addCart, editCart, deleteProduct } from "../Redux/Redux";
import noimage from "../../src/images/noimage.jpg";
import { Modal, Button, notification } from 'antd';
import { getCartListAPI, getWishListAPI, getCountryListAPI, checkPopupForfirstTimeAPI } from "../Redux/Api";
import { updateCartStateValue, updateWishListStateValue, updatecountryStateValue, updatecheckFirstTimeStateValue } from "../Redux/Redux";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}
const ProductWrap = ({ product }) => {
  const [showAlert, setShowAlert] = useState(false);
  const cart = useSelector(state => state.cart.value)
  const dispatch = useDispatch();
  const wishlist = useSelector(state => state.wishlist.value)
  const [hasItemsInWishlist, setHasItemsInWishlist] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [variants, setVariants] = useState([]);
  useEffect(() => {
    if (product.varients) {
      const parsedVariants = JSON.parse(product.varients).Color;
      setVariants(parsedVariants);
    }
  }, [product]);
  function storeProductID(productid) {
    localStorage.setItem("productid", productid);
  }
  const showNotificationforCart = () => {
    notification.success({
      message: 'Product added to the cart',
    });
  };
  const alreadyadded = ()=>{

    notification.success({
      message: 'Product already added to the cart',
    });
  };
  const addToCart = async (productId, wishlistid) => {

    try {
      if (!localStorage.getItem("token")) {
        navigate('/LoginPage')
      }
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/cart/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            // clientid: cartid,
            clientid: localStorage.getItem("clientid"),
            productid: productId,
            productquantity: 1,
          }),
        }
      );

      if (response.ok) {
        // addCart(productId);
        dispatch(addCart(productId));
        // removeProductID(wishlistid)
        showNotificationforCart();
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      let cartData = await getCartListAPI();
      dispatch(updateCartStateValue(cartData));
      // if (window.location.href.toLowerCase().includes("cart")) {
      //   window.location.reload();
      // }
    }
  };
  const closeAlert = () => {
    setShowAlert(false);
  };
  const addToWishList = (productid) => {
    if (!localStorage.getItem("token")) {
      setShowAlert(true);
      // Show alert and return early if clientid is not present
      return;
    }
    const isAlreadyInWishlist = wishlist.some(item => item.productid === productid);

    if (isAlreadyInWishlist) {
      // If the product is already in the wishlist, remove it
      const wishlistid = wishlist.find(item => item.productid === productid).wishlistid;
      removeProductID(wishlistid);
    } else {
      // If the product is not in the wishlist, add it
      dispatch(addProduct(productid));
      notification.success({
        message: 'Product Added to Wishlist',
        duration: 3 // Duration in seconds
      });
    }

    // Update the local state or perform any other necessary actions
    setHasItemsInWishlist(!isAlreadyInWishlist);
  };

  // function removeProductID(wishlistid) {
  //   dispatch(deleteProduct(wishlistid))
  //   notification.success({
  //     message: 'Product Removed to Wishlist',
  //     duration: 3 
  //   });
  // }

  const removeProductID = (wishlistid) => {
    const removeConfirmation = () => {
      Modal.confirm({
        title: 'Are you sure you want to remove this product from your Wishlist?',
        onOk() {
          dispatch(deleteProduct(wishlistid));
          notification.success({
            message: 'Product Removed from Wishlist',
            duration: 3 // Duration in seconds
          });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

    // Check if the current page is the Wishlist page
    if (window.location.pathname === '/WishList') {
      removeConfirmation();
    } else {
      // On other pages, directly remove the product and show a notification
      dispatch(deleteProduct(wishlistid));
      notification.success({
        message: 'Product Removed from Wishlist',
        duration: 3 // Duration in seconds
      });
    }
  };
  const priceDifference = (product.mrpprice - product.sellingprice);
  const truncatedNumber = (Math.floor(priceDifference * 100) / 100).toFixed(2);
  const [imageClass, setImageClass] = useState('');
  useEffect(() => {
    const imageElement = new Image();
    imageElement.src = product.previewimg ? product.previewimg.file : '';

    imageElement.onload = () => {
      const { width, height } = imageElement;

      // Determine if the image is horizontal, vertical, or square
      if (width > height) {
        setImageClass('img-horizontal');
      }
      else if (width === height) {
        setImageClass('img-horizontal');
      }
      else {
        setImageClass('img-vertical');
      }
    };
  }, [product.previewimg]);
  const isProductInCart = cart.some(
    (item) => item.productid === product.productid
  );
  const handleCartClick = async () => {
    try {
      if (!localStorage.getItem("token")) {
        setShowAlert(true);
        return;
      }
      dispatch(addCart(product.productid))
      // const response = await fetch(
      //   "http://68.178.169.226:12080/api/v1/cart/add",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     },
      //     body: JSON.stringify({
      //       // clientid: cartid,
      //       clientid:localStorage.getItem("clientid"),
      //       productid: data.productid,
      //       productquantity: 1,
      //     }),
      //   }
      // );

      // if (response.ok) {
      //   handleCart();
      // }
    } catch (error) {
      console.error("Error checking authentication:", error);
    }
  };
  const handleNavigate = () => {
    if (localStorage.getItem('clientid')) {
      navigate('/Cart')
    }
    else {
      <Modal
        open={showAlert}
        onCancel={closeAlert}
        footer={[
          <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
            closeAlert();
            window.location.href = '/LoginPage';
          }}>
            Login
          </Button>,
        ]}
      >
        <p>Please Login to Proceed.</p>
      </Modal>
    }
  }
  return (
    <div className="col-xs-6 col-sm-4 col-xs-2 col-md-3">
      <div className="product-wrap " key={product.productid}>
        <Link
          to={`/Product/${product.productid}`}
          key={product.productid}
          onClick={() => {
            storeProductID(product.productid);
          }}
        >


          <div className="product">

            <figure className={`product-media ${imageClass}`}>

              {product.previewimg ? (
                <Link
                  to={`/Product/${product.productid}`}
                  onClick={() => {
                    storeProductID(product.productid);
                  }}
                >


                  <img
                    src={product.previewimg.file}
                    alt={product.productid}

                  />
                </Link>
              ) : (
                <div className="product-not-available">
                  <Link
                    to={`/Product/${product.productid}`}
                    onClick={() => storeProductID(product.productid)}
                  >
                    <img
                      src={noimage}
                      alt={product.productid}

                    />
                  </Link>
                </div>
              )}


              {product.mrpprice !== product.sellingprice &&
                <div className="save-price">
                  Save<br />
                  <span>
                    {localStorage.getItem('currencySymbol')}{" "}{truncatedNumber}
                  </span>
                </div>}

              <div className="product-test-wishlist relative"> <>
                <div className="absolute z-10 right-4 top-3">
                  <div
                    className={`btn-product-icon btn-wishlist ${wishlist.some(
                      (wishlistItem) =>
                        wishlistItem.productid === product.productid
                    )
                      ? "wishlist-has-items"
                      : ""
                      }`}
                    title="Add to wishlist"
                    onClick={(e) => {
                      e.preventDefault();
                      addToWishList(product.productid);
                    }}
                  >
                    <i className="d-icon-heart-full"></i>
                  </div>
                </div>

              </></div>
              <div className="product-action"></div>
            </figure>
            <div className="product-details">
              <h3 className=" text-xl font-light capitalize line-clamp-2">
                {truncateText(product.productname, 40)}
              </h3>


              {product.weight && product.productunit && (
                <div className="product-unit-and-weight">
                  {product.weight} {product.productunit}
                </div>
              )}
              <div className="product-price">
                {product.mrpprice !== product.sellingprice ? (
                  <>
                    <ins className="new-price">
                      {localStorage.getItem('currencySymbol')}
                      {product.sellingprice}
                    </ins>
                    <del className=" text-red-500 opacity-70 " style={{ color: "var(--rm-red)" }}>
                      {localStorage.getItem('currencySymbol')}
                      {product.mrpprice}
                    </del>
                  </>
                ) : (
                  <span className="single-price">
                    {localStorage.getItem('currencySymbol')}
                    {product.sellingprice}
                  </span>
                )}
              </div>
              <div className="row d-flex justify-content-between rating-main">

                <div className="col-md-6 d-flex align-items-center">
                  <div className="ratings-container">
                    <div className="ratings-full">
                      <span className="ratings"></span>
                      <span className="tooltiptext tooltip-top"></span>
                    </div>
                    <a href={`product.html?id=${product.id}`} className="rating-reviews">
                      {/* ({product.review.review_count} reviews) */}
                    </a>
                  </div>
                </div>


                {product.quantity > 0 ? (<div className="col-md-6">
                  <div className="sold-out-label">
                    Sold  {product.soldquantity}/{product.quantity}
                  </div>
                </div>) : (<div className="soldout-thumb">Sold </div>)}

                {/* <div className="soldout-thumb m-0 p-0">{product.sellername}</div> */}
                {/* <div className="soldout-thumb m-0 p-0">{product.countryoforigin}</div> */}


              </div>

            </div>
          </div>

          {product.remainingTime && <div className="offer_timing">
            {product.remainingTime.includes('Day') ? product.remainingTime :
              <>
                <span className="time">
                  {product.remainingTime.split(':')[0]}
                </span>
                <span className="text">
                  {"Hr "}
                </span>

                <span className="time">
                  {product.remainingTime.split(':')[1]}
                </span>
                <span className="text">
                  {"Min "}
                </span>

                <span className="time">
                  {product.remainingTime.split(':')[2]}
                </span>
                <span className="text">
                  {"Sec "}
                </span>

              </>
            }</div>}
        </Link>
        <div className="product-qty">
          <button
            className="cart-btn"
            onClick={(e) => {
              e.preventDefault();
              isProductInCart ? alreadyadded():
              addToCart(product.productid);
            }}
            // disabled={isProductInCart} // Optionally disable the button after adding to the cart
          >
            {isProductInCart ? "Added to Cart" : "Add to Cart"}
          </button>
        </div>
        {/* <div className="variants">
        {[...new Set(variants && variants.map((variant) => variant.color))].map((color, index) => (
            <Link
            key={variants.productid}
            to={`/Product/${product.productid}`}
          >
          <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: color,
                border: '1px solid black',
                borderRadius: '50%',
              }}
            ></div>
          </div> 
          </Link>
        ))}
      </div> */}
        {/* {location.pathname === "/HomePage" ? null : (
            <>
             
              <div className="product-form product-qty">
                <div className="product-form-group">
                {product.quantity > 0 ? (
                  <>
                                  {!isProductInCart && <button
                    className="cart-btn"
                    onClick={() => {
                      handleCartClick();
                      handleNavigate();
                    }}
                  >
                    <i className="d-icon-bag"></i> {'Buy'}
                  </button>}
                  
                {!isProductInCart && <button
                    className="cart-btn"
                    onClick={handleCartClick}
                  >
                    <i className="d-icon-bag"></i> {'Add to Cart'}
                  </button>}
                  

                  {isProductInCart &&
                    <div className="max-w-xs" onClick={(e) => e.preventDefault()}>
                      <label htmlFor="quantity-input" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Quantity:</label>
                      <div className="product-qty">
                        <button type="button" className="product-qty-sub"
                          onClick={(e) => {
                            e.preventDefault();
                            updateProductQuantity(product.productid, 'sub')
                          }}
                        >
                          <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                          </svg>
                        </button>price
                        <span className="  " >
                          {cart.find((cartItem) => cartItem.productid === product.productid).productquantity}
                        </span>
                        <button type="button" className="product-qty-add"
                          onClick={(e) => {
                            e.preventDefault();
                            updateProductQuantity(product.productid, 'add')
                          }}
                        >
                          <svg className="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  }
                  </>
                ) : (
                  <button className="cart-btn">Sold Out</button>
                )}

                </div>
              </div>
               
            </>
           )} */}

        {cart.some(
          (cartItem) => cartItem.productid === product.productid
        ) ? (
          // <button className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold">
          //   In Cart
          // </button>
          <div className="max-w-xs" onClick={(e) => e.preventDefault()}>
            {/* <label htmlFor="quantity-input" className="block mb-2 text-base font-semibold text-gray-900 dark:text-white">Quantity:</label>
          <div className="product-qty">
            <button type="button" className="product-qty-sub"
              onClick={(e) => {
                e.preventDefault();
                updateProductQuantity(product.productid, 'sub')
              }}
            >
              <svg className=" " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
              </svg>
            </button>
            <span className=" " >
              {cart.find((cartItem) => cartItem.productid === product.productid).productquantity}
            </span>
            <button type="button" className="product-qty-add"
              onClick={(e) => {
                e.preventDefault();
                updateProductQuantity(product.productid, 'add')
              }}
            >
              <svg className=" " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
              </svg>
            </button>
          </div> */}
          </div>
        ) : (
          ''
        )}
        <Modal
          open={showAlert}
          onCancel={closeAlert}
          footer={[
            <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
              closeAlert();
              window.location.href = '/LoginPage';
            }}>
              Login
            </Button>,
          ]}
        >
          <p>Please Login to Proceed.</p>
        </Modal>
      </div>
    </div>
  );
}
export default ProductWrap;
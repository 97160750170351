import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import "../../src/navbarHead/navmenu.css";

function NavHead() {
  const [categories, setCategories] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Adjust the breakpoint as needed


  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory",{
          currency: localStorage.getItem('currencyName')
        });
        if (response.status === 401 || response.status === 404) {
          return;
        }
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
<div className="sidebar-overlay"></div>
<a href="#" className="sidebar-toggle"><i className="fas fa-chevron-right"></i></a>
<a className="sidebar-close" href="#"><i className="d-icon-times"></i></a>
<div className="sidebar-content">
<div className="sticky-sidebar" data-sticky-options="{'top': 10}">
<div className="widget">
<ul className="menu vertical-menu category-menu">
  {categories.map((category, index) => (
    <li key={index} className={category.subcategory && category.subcategory.length > 0 ? "submenu" : ""}>
      <Link to={`/MenuCategory/${category.categoryid}`}>
        {category.categoryname}
      </Link>
      {category.subcategory && category.subcategory.length > 0 && (
        <ul className="megamenu">
          {category.subcategory.map((subcategory, subIndex) => (
            <li key={subIndex} className="" style={{minWidth:"30em"}}>
              <Link to={`/MenuSub/${subcategory.subcategoryid}`}>
              <h4 className="menu-title">{subcategory.subcategoryname}</h4></Link>
              <hr className="divider" />
              {subcategory.subsubcategory && subcategory.subsubcategory.length > 0 && (
                <ul>
                  {subcategory.subsubcategory.map((subsubCategory, subsubIndex) => (
                    <li key={subsubIndex}>
                      <Link to={`/MenuSubSubCategory/${subsubCategory.subsubcategoryid}`}>
                        {subsubCategory.subsubcategoryname}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  ))}
</ul>

</div>
</div>
</div>
</div>
  );
}

export default NavHead;

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Ecomheader from "../header/headershop";
import Ecomfooter from "../footer/footershop";
import { Helmet } from "react-helmet";
import axios from "axios";
import Select from "react-select";
function ShippingForm() {
  const mobilePrefix = localStorage.getItem('mobileprefix');
  const countrySelection = localStorage.getItem('selectedCountry');
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [fname, setName] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [clientid, setClientid] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [pincodeList, setPincodeList] = useState([]);
  const [mobileprefixforChange, setMobilePrefix] = useState('');
  const [mobilemaxlengthforChange, setMobileMaxLength] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const handleCountryClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setSelectedCountry(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  const handleStateClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setSelectedState(selectedValue);

    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchPincodes(selectedCountry, selectedValue);
    }
  };
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const id = data.id; // Assuming the response provides the id
          localStorage.setItem("clientid", id); // Store the id in localStorage
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    // Call the fetchClientDetails function when the component mounts
    fetchClientDetails();
  }, []);

  const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (phonenumber.length < + parseInt(localStorage.getItem('mobilemaxlength'))) {
      // Show an error message for less than 10 digits
      setErrorMessage("Please enter" + localStorage.getItem('mobilemaxlength') + "digits.");
      return;
    }
    setError(null);

    try {
      setLoading(true);
      // TODO: Replace with actual API call
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/client/addaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            clientid: localStorage.getItem("clientid"),
            name: fname,
            country: selectedCountry,
            region,
            address1,
            address2,
            city,
            state: selectedState,
            pincode,
            phonenumber,
            email,
          }),
        }
      );

      if (response.status === 401 || response.status === 404) {
        setError("Unauthorized or Not Found");
        setLoading(false);
        return;
      }

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
        setLoading(false);
        navigate(-1);
      } else {
        setError("Failed to add address");
        setLoading(false);
      }
    } catch (error) {
      setError("An error occurred");
      setLoading(false);
    }
  };
  const handleMobileChange = (e) => {
    const newValue = e.target.value;
    const maxLength = selectedCountries ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'));
    // Check if the input consists of only digits and has a maximum length of 10 characters
    if (/^\d*$/.test(newValue) && newValue.length <= maxLength) {
      setPhonenumber(newValue);
      setErrorMessage("");
    } else {
      // Set an error message when the input is invalid
      setErrorMessage(
        "Phone number must be a maximum of" + localStorage.getItem('mobilemaxlength') + "digits and contain only numbers."
      );
    }
  };

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);

  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/pincode/getstate",
        {
          country: selectedCountry,
        }
      );

      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching the list of states:", error);
    }
  };
  const fetchPincodes = async (selectedCountry, selectedState) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/pincode/getpincode",
        {
          country: selectedCountry,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const stateOptions = stateList.map((stateItem) => ({
    value: stateItem.state,
    label: stateItem.state,
  }));
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);
  const handlePincodeChange = (selectedOption) => {
    setPincode(selectedOption.value);
  };
  const handleCancel = () => {
    navigate(-1);
  }

  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('http://68.178.169.226:12080/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          setCountriesList(data);
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);
  useEffect(() => {
    setSelectedCountries(selectedCountries);
  }, []);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const handleCountrySelect = (selectedCountries) => {
    const selectedCountryData = countriesList.find(countryData => countryData.country === selectedCountries);
    if (selectedCountryData) {
      const { symbol, currencycode, mobilemaxlength, mobileprefix } = selectedCountryData;
      setSelectedCountries(selectedCountries);
      setMobilePrefix(mobileprefix);
      setMobileMaxLength(mobilemaxlength);
    }
    else {
      console.error(`Data for ${selectedCountries} not found in the countryList`);
    }
  }
  
  return (
    <div>
      <div className="container-fluid">
        <div>
          <Helmet>
            <title>Shipping Address</title>
          </Helmet>
        </div>
        <Ecomheader />
        <div className="container homebg pt-10">
          <div className="row  d-flex justify-content-center  align-self-center ">
          
            <div className="col-lg-6 col-md-6 col-sm-12 form-box-for-border">
              <div className="form-box-header">
                <h2 >Add Shipping Address </h2>
              </div>
              <div className="container-fluid">
              <form action="#" className="form" onSubmit={onFinish}>
               <div className="row">
                 <div className="col-md-12 col-sm-12 "><label>Name *</label>
                   <input
                     type="text"
                     className="form-control"
                     name="first-name"
                     required
                     value={fname}
                     onChange={(e) => setName(e.target.value)}
                   /></div>
               </div>
               <div className="row">
                 <div className="col-md-12  col-sm-12 ">
                   <label>Phone *</label>
                   {/* <select
                     className="form-control"
                     value={selectedCountries}
                     onChange={(e) => handleCountrySelect(e.target.value)}
                   >
                     {countriesList.map((countryData) => (
                       <option key={countryData.countryselectionid} value={countryData.country}>
                         {countryData.country}{countryData.mobileprefix}
                       </option>
                     ))}
                   </select> */}
                   <div className="input-group-prepend">
                     <span className="input-group-text">{selectedCountries ? mobileprefixforChange : mobilePrefix}</span>
                   </div>
                   <input
                     type="text"
                     className="form-control"
                     name="phone"
                     required
                     value={phonenumber}
                     onChange={handleMobileChange}
                   />
                   <div style={{ color: "red" }}>{errorMessage}</div></div>
                   </div>
                   <div className="row">
                 <div className="col-md-12  col-sm-12 "><label>Email Address *</label>
                   <input
                     type="email"
                     className="form-control"
                     name="email-address"
                     required
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                   /></div>
               </div>
 
               <div className="row">
                 <div className="col-md-12  col-sm-12 "><label>Street Address  *</label>
                   <textarea
                     type="text"
                     className="form-control"
                     name="address1"
                     required
                     placeholder="House number and street name"
                     value={address1}
                     onChange={(e) => setAddress1(e.target.value)}
                     rows="2" cols="50"
                     style={{resize: "none"}} 
                   /></div>
                   </div>
                   <div className="row">
                 <div className="col-md-12  col-sm-12 mt-2">
                   <label>Street Address 2 *</label>
 
                   <textarea
                     type="text"
                     className="form-control"
                     name="address2"
                     // required
                     placeholder="Apartment, suite, unit, etc. (optional)"
                     value={address2}
                     onChange={(e) => setAddress2(e.target.value)}
                     rows="2" cols="50"
                     style={{resize: "none"}}
                   />
                 </div>
               </div>
               <div className="row">
 
                 <div className="col-md-12  col-sm-12 mt-2"> <label>Country *</label>
                   <select
                     className="form-control"
                     name="country"
                     required
                     value={selectedCountry}
                     onChange={(e) => handleCountryClick(e.target.value)}
                   >
                     <option value=""></option>
                     {countryList.map((country) => (
                       <option key={country.pincodeid} value={country.country}>
                         {country.country}
                       </option>
                     ))}
                   </select></div>
                   </div>
                   <div className="row">
                 <div className="col-md-12  col-sm-12  mt-2"><label>State *</label>
                 <Select
            options={stateList.map((stateItem) => ({
              value: stateItem.state,
              label: stateItem.state,
            }))}
            value={{ value: selectedState, label: selectedState }}
            onChange={(selectedOption) => handleStateClick(selectedOption.value)}
            isSearchable={true} // Enable search option
            placeholder="Select State"
          />
                   </div>
               </div>
 
               <div className="row">
                 <div className="col-md-12  col-sm-12 mt-2">
                   <label>Town / City *</label>
                   <input
                     type="text"
                     className="form-control"
                     name="city"
                     required
                     value={city}
                     onChange={(e) => setCity(e.target.value)}
                   />
                 </div>
                 </div>
                 <div className="row">
                 <div className="col-md-12  col-sm-12 mt-2">
                   <label>ZIP *</label>
                   <Select
                     options={pincodeList.map((pincodeItem) => ({
                       value: pincodeItem.pincode,
                       label: pincodeItem.pincode,
                     }))}
                     value={{ value: pincode, label: pincode }}
                     onChange={handlePincodeChange}
                     isSearchable={true} // Enable search option
                     placeholder="Select PIN Code"
                   />
                 </div>
               </div>
 
               <div className="row mt-5">
                 <div className="col-md-12 d-flex justify-content-center">
                   <div>
                     <button type="submit" onClick={handleCancel} className="btn btn-dark btn-rounded ">
                       Remove
                     </button></div>
                   <div>
                     <button type="submit" className="btn btn-dark btn-rounded ">
                       Add  Address
                     </button></div>
 
                 </div>
               </div>
               </form>
             </div>
              
              </div>
              </div>

          
            
        

        </div>
      </div>
      <Ecomfooter />
    </div>
  );
}
export default ShippingForm;
